type Route = {
  path: string
  label: string
}

type Routes = { [key: string]: Route }

export const routes: Routes = {
  activateDevice: { path: '/activate', label: 'menu.activateDevice' },
  devices: { path: '/devices', label: 'menu.devices' },
  login: { path: '/login', label: 'menu.login' },
  activateAccount: { path: '/activate-account', label: 'menu.activateAccount' },
  confirmEmailUpdate: {
    path: '/confirm-email',
    label: 'menu.confirmEmailUpdate',
  },
  editCreditCard: { path: '/edit-credit-card', label: 'menu.editCreditCard' },
  mobileApp: { path: '/mobile-app', label: 'menu.mobileApp' },
  account: { path: '/account', label: 'menu.account' },
  resetPassword: { path: '/accounts/set-password', label: '' },
  deleteAccount: { path: '/delete-account', label: 'menu.mobileApp' },
  openChat: { path: '/open-chat', label: 'menu.mobileApp' },
  supportCenter: { path: '/open-support-center', label: 'menu.mobileApp' },
  support: { path: '/support', label: 'menu.mobileApp' },
}
