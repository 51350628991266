import { ReactComponent as Logo } from '../../images/TellyLogo.svg'
import { useRef } from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title,
  LogoContainer,
  Main,
  Message,
  Text,
  RightBackground,
  Footer,
  GoToTellyApp,
} from './EditCreditCard.styles'
import { getEditCreditCardsURI } from 'constants/mobile'

export const EditCreditCard: React.FC = (): JSX.Element => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  return (
    <Wrapper>
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>

      <Main>
        <Container>
          <Title>Almost there!</Title>
          <Message>
            Please open link on your mobile phone to update your credit card
            information.
          </Message>
          <Text>
            Check your email for the credit card update link we sent you and
            make sure to open the link on a mobile device with Telly installed.
          </Text>
          <GoToTellyApp ref={anchorRef} href={getEditCreditCardsURI()}>
            Go to Telly Mobile App
          </GoToTellyApp>
        </Container>
        <RightBackground />
      </Main>
      <Footer />
    </Wrapper>
  )
}
