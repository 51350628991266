/**
 * Mobile scheme
 *
 * @see https://github.com/TeeVeeCorp/mobile/blob/main/src/utils/deepLinksConfig.ts
 */
export const mobileScheme = 'telly://'

/**
 * @description Get mobile URI for credit card information update
 *
 * @example telly://edit-credit-card
 */
export const getEditCreditCardsURI = (): string =>
  `${mobileScheme}edit-credit-card`

/**
 * @description Get mobile URI for activation account
 *
 * @param code - Activation code
 * @example telly://activate-account?code=123456
 */
export const getActivateAccountMobileURI = (code: string): string =>
  `${mobileScheme}activate-account?code=${code}`

/**
 * @description Get mobile URI for confirming new email address
 *
 * @param code - Activation code
 * @example telly://update-email?code=1234asdf
 */
export const getConfirmEmailMobileURI = (code: string): string =>
  `${mobileScheme}update-email?code=${code}`

/**
 * @description Get mobile URI for set password account
 *
 * @param code - Reset code
 * @example telly://accounts/set-password?code=123456
 */
export const getSetPasswordMobileURI = (code: string): string =>
  `${mobileScheme}accounts/set-password?code=${code}`

/**
 * @description Get mobile URI for mobile-app
 *
 * @example telly://mobile-app
 */
export const getMobileAppURI = (): string => `${mobileScheme}mobile-app`

export const getDeleteAccountMobileURI = (): string =>
  `${mobileScheme}delete-account`

export const getOpenChatMobileURI = (): string => `${mobileScheme}open-chat`

export const getOpenTicketCreationMobileURI = (params?: string): string =>
  `${mobileScheme}support${params}`

export const getOpenSupportCenterMobileURI = (): string =>
  `${mobileScheme}open-support-center`
